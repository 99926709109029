<template>
  <diva-card>
    <template v-slot:filters>
      <div class="filters__item">
        <base-button size="sm" type="primary" @click="selectAllProfiles">{{getTrans('messages.select_all')}}</base-button>
        <base-button size="sm" type="primary" @click="deselectAllProfiles">{{getTrans('messages.deselect_all')}}</base-button>
      </div>
      <div class="filters__item">
        <base-input
          :name="'search'"
          :type="'text'"
          v-model="filter.search"
          :placeholder="getTrans('messages.search')"
          :formClasses="'filters__input-wrapper'"
          @keypress.enter="loadProfiles()"
          @change="checkSearch()"
          :error="searchError"
          :inputClasses="'filters__input'">
        </base-input>
      </div>
      <div class="filters__item">
        <el-select v-model="filter.platform" :class="'filters__select'" filterable @change="loadProfiles()">
          <el-option
            v-for="(option, index) in camsites"
            :class="'filterOptionClass'"
            :value="option.id"
            :key="index"
            :label="option.title"
          >{{option.title}}</el-option>
        </el-select>
      </div>
      <div class="filters__item" v-if="!link">
        <el-select v-model="filter.online" :class="'filters__select'" @change="loadProfiles()">
          <el-option :class="'filterOptionClass'" :value="''" :label="getTrans('messages.online') + '/' + getTrans('messages.offline')">{{getTrans('messages.online')}}/{{getTrans('messages.offline')}}</el-option>
          <el-option :class="'filterOptionClass'" :value="1" :label="getTrans('messages.online')">{{getTrans('messages.online')}}</el-option>
          <el-option :class="'filterOptionClass'" :value="0" :label="getTrans('messages.offline')">{{getTrans('messages.offline')}}</el-option>
        </el-select>
      </div>
      <div class="filters__item">
        <base-button size="sm" type="success" @click="loadProfiles()">{{getTrans('messages.search')}}</base-button>
      </div>
      <div class="filters__item">
        <base-button size="sm" type="warning" @click="resetFilters">{{getTrans('messages.reset')}}</base-button>
      </div>
    </template>

    <div class="mx-n3 mt-n3" :class="scroll ? 'scrolled-content-wrapper' : ''" @scroll="handleScroll">
      <content-loader class="mt-3" v-if="loading && profiles.length === 0"></content-loader>
      <template v-else>
        <NothingFound v-if="profiles.length === 0">
          {{getTrans('messages.no_profiles')}}
          <div>
            <router-link :to="{name: 'add_profile'}">
              <base-button size="sm" type="primary">{{getTrans('messages.add_profile')}}</base-button>
            </router-link>
          </div>
        </NothingFound>
        <template v-else>
          <store-pagination class="mt-3" v-if="!scroll" :store="'Profile'" @update:modelValue="loadProfiles"></store-pagination>
          <profiles-card-list v-if="listView === 'card'" :profiles="profiles" v-model:selected="selected" :link="link"></profiles-card-list>
          <profiles-table v-else
            :profiles="profiles"
            :sorting="sorting"
            v-model:selected="selected"
            @sorted="sort"
            :linkView="link"
            :show-model="userCan('studio-owner')"
          ></profiles-table>
        </template>
      </template>

      <div v-if="scroll">
        <div class="text-center" v-if="loading && profiles.length !== 0">
          <i class="fas fa-spinner fa-2x fa-spin"></i>
        </div>
        <div class="text-center" v-else-if="!isLastPage()">
          <base-button size="sm" type="secondary" @click="loadMore()">{{getTrans('messages.load_more')}}</base-button>
        </div>
      </div>
    </div>
  </diva-card>
</template>
<script>

import { mapGetters } from "vuex";
import { ElSelect, ElOption } from "element-plus";
import ProfilesTable from "@/views/Profiles/Components/ProfilesTable";
import ProfilesCardList from "@/views/Profiles/Components/ProfilesCardList";
import InfiniteScroll from "@/mixins/InfiniteScroll";
import Filters from "@/mixins/Filters";
import StorePagination from "@/components/StorePagination";
import NothingFound from "@/components/Content/NothingFound";

export default {
  name: 'ProfilesFilterList',
  mixins: [InfiniteScroll, Filters],
  emits: ['update:selectedProfiles'],
  components: {
    ElSelect,
    ElOption,
    ProfilesTable,
    ProfilesCardList,
    StorePagination,
    NothingFound
  },
  props: {
    selectedProfiles: {
      type: Object,
      default: () => {
        return {};
      },
    },
    link: {
      type: Boolean,
      default: false
    },
    listView: {
      type: String,
      default: 'table'
    },
    scroll: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      profiles: [],
      filter: {
        platform: '',
        online: '',
        search: '',
        type: this.link ? 2 : 1
      },
      sorting: {
        ids_first: true
      },
      searchError: '',
      selected: {...this.selectedProfiles},
    };
  },
  computed: {
    ...mapGetters('Profile', {
      storeProfiles: 'getList',
      loading: 'listLoading',
      pagination: 'pagination',
    }),
    ...mapGetters("Link", ["getLinks"]),
    ...mapGetters('User', {userCan: 'can'}),
    camsites() {
      let camsites = this.$store.getters['Platform/' + (this.link ? 'getClipsites' : 'getCamsites')];
      if (camsites) {
        camsites = [...camsites];
        camsites.unshift({id: '', title: this.getTrans('messages.any_platform')});
      }
      return camsites;
    }
  },
  methods: {
    selectAllProfiles() {
      let selected = {};
      this.profiles.forEach((p) => {
        selected[p.id] = p;
      });
      this.selected = selected;
    },
    deselectAllProfiles() {
      this.selected = {};
    },
    resetFilters() {
      for (let key in this.filter) {
        if (key !== 'type') {
          this.filter[key] = '';
        }
      }
      this.loadProfiles();
    },
    checkSearch() {
      if (this.filter.search.length > 0 && this.filter.search.length < 4) {
        this.searchError = this.getTrans('messages.minimum_symbols_4');
      } else {
        this.searchError = '';
      }
    },
    loadProfiles(page = 1, apply = false) {
      this.checkSearch();
      if (!this.searchError) {
        if (!apply) {
          this.profiles = [];
        }

        this.$store.dispatch('Profile/getProfiles', {
          page: page,
          filter: this.collectFilters(),
          sorting: this.sorting.ids_first ? {ids_first: Object.keys(this.selected)} : this.sorting,
          include: this.listView === 'table' ? ['model', 'defaultMaxMinViewers'] : []
        }).then(() => {
          this.profiles = apply ? this.profiles.concat(this.storeProfiles) : this.storeProfiles;
        });
      }
    },
    emitSelected() {
      this.$emit('update:selectedProfiles', this.selected);
    },
    handleScroll(el) {
      if (!this.loading && this.scrollShouldLoadMore(el)) {
        this.loadMore();
      }
    },
    loadMore() {
      this.loadProfiles(this.pagination.current_page + 1, true);
    },
    sort(sorting) {
      this.sorting = {
        [sorting.column]: sorting.sorting
      };
      this.loadProfiles();
    }
  },
  mounted() {
    this.loadProfiles();
  },
  watch: {
    selected() {
      this.emitSelected();
    },
    selectedProfiles(newValue) {
      if (newValue !== this.selected) {
        this.selected = {...newValue};
        const ids = [];
        this.profiles.forEach(profile => {
          ids.push(profile.id);
        });
        // Reload profiles if selected are not included to current result.
        let needReload = false;
        for (let id in this.selected) {
          if (!ids.includes(parseInt(id))) {
            needReload = true;
          }
        }
        if (needReload) {
          this.loadProfiles();
        }
      }
    }
  }
};
</script>
